import { FC } from 'react'
import { Button } from '@/ui/kit'
import clsx from 'clsx'
import styles from '../style.module.scss'
import { t } from '@lingui/macro'
import { ITeamFormUserSubscriptionProps } from '@/ui/molecules/TeamForm/TeamForm.Types'

export const RiskManagerError: FC<ITeamFormUserSubscriptionProps> = ({ handleClose }) => {
  return (
    <div className={clsx(styles.root, styles.errorWrap)}>
      <div className={clsx(styles.subtitle)}>{t({ id: 'riskManager.error', comment: 'Возникла ошибка' })}</div>
      <Button.Primary
        label={t({
          id: 'core.close',
          comment: 'Close',
        })}
        className={clsx(styles.btnWide, styles._withMargin)}
        onClick={handleClose}
      />
    </div>
  )
}
