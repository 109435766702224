import { TLanguage } from '@/core/constants'

type TLinksRecord = Partial<{
  twitter: string
  telegram: string
  discord: string
  youtube: string
  tt_copy: string
  tt_macos: string
  tt_windows: string
  tt_cdn_proscalping: string
}>

export const links: Partial<Record<TLanguage, TLinksRecord>> = {
  en: {
    twitter: 'https://twitter.com/tiger_trade',
    telegram: 'https://t.me/tigertrade',
    discord: 'https://discord.gg/qQupZgxGtv',
    youtube: 'https://www.youtube.com/@TigerDotCom',
    tt_copy: 'https://copy.tiger.trade/index-en.html',
    tt_macos: 'https://macos.tiger.trade/',
    tt_windows: 'https://www.tiger.com/terminal',
    tt_cdn_proscalping: 'https://cdn-gl.tiger.trade/download/proscalping.ttc',
  },
  ru: {
    telegram: 'https://t.me/tigercom_ru',
    tt_copy: 'https://copy.tiger.trade/index-ru.html',
    tt_windows: 'https://www.tiger.com/ru/terminal',
    youtube: 'https://www.youtube.com/@TigerDotCom',
  },
  uk: {
    telegram: 'https://t.me/tigercom_ru',
    youtube: 'https://www.youtube.com/@TigerDotCom',
  },
}

export const getTtLinksByLocale = (link: keyof TLinksRecord, locale: TLanguage): string => {
  return links[locale]?.[link] ?? links.en?.[link] ?? ''
}
