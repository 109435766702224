import { TRiskManagerTradingStatus } from '../riskManager/riskManager.types'
import {
  E_RISK_MANAGER_DRAWDOWN_TYPE,
  E_RISK_MANAGER_TYPE,
  E_TEAM_USER_STATUS,
  TRiskManagerSettings,
  TTeamInfo,
  TTeamManagerInfo,
  TTeamUserInfo,
} from './team.types'

export const TEAM_USER_DATA_DEFAULT: TTeamUserInfo = {
  applicationStatus: E_TEAM_USER_STATUS.none,
  cashbackPercent: null,
  riskManagerEnabled: false,
  teamTitle: '',
  teamCode: '',
  riskManagerAvailable: false,
  statisticId: null,
}

export const TEAM_MANAGER_DATA_DEFAULT: TTeamManagerInfo = {
  gainByMonth: 0,
  numberOfMembers: 0,
  referralCode: '',
  teamCode: '',
  teamLogoUrl: '',
  teamTitle: '',
  binanceCashbackPercent: undefined,
  bybitCashbackPercent: undefined,
  okxCashbackPercent: undefined,
  statisticId: null,
}

export const RISK_MANAGER_DATA_DEFAULT: TRiskManagerSettings = {
  botHandle: '',
  drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE.percent,
  drawdownValue: 0,
  enabled: false,
  permissions: [],
  userHandle: '',
  type: E_RISK_MANAGER_TYPE.external,
}

export const TEAM_REQUESTED_DATA_DEFAULT: TTeamInfo = {
  teamCode: '',
  teamTitle: '',
  permissions: [],
}

export const TRADING_STATUS_DEFAULT: TRiskManagerTradingStatus = {
  tradingEnabled: true,
  until: '',
}
