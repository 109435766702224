import {
  GetRiskManagerTC,
  SetRiskManagerTC,
  UpdateRiskManagerTC,
  DeleteRiskManagerTC,
  GetRiskManagerTradingStatusTC,
  GetRiskManagerEventsTC,
  GetRiskManagerAvailableTC,
  GetRiskManagerEventByIdTC,
} from './riskManager.thunk'

export const riskManagerAsyncActions = {
  GetRiskManagerTC,
  SetRiskManagerTC,
  UpdateRiskManagerTC,
  DeleteRiskManagerTC,
  GetRiskManagerTradingStatusTC,
  GetRiskManagerEventsTC,
  GetRiskManagerAvailableTC,
  GetRiskManagerEventByIdTC,
}
