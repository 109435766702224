import {
  GetAssetsForTransactionsTC,
  GetAssetsForWithdrawAndDepositTC,
  GetCoinsBalanceTC,
  GetDepositWalletAddressTC,
  TransferAssetTC,
  WithdrawAssetTC,
  GetExchangeHistoryTC,
  GetWithdrawAddressTC,
} from './transactions.thunks'

export const transactionsAsyncActions = {
  TransferAssetTC,
  GetAssetsForTransactionsTC,
  GetDepositWalletAddressTC,
  GetAssetsForWithdrawAndDepositTC,
  GetCoinsBalanceTC,
  WithdrawAssetTC,
  GetWithdrawAddressTC,
  GetExchangeHistoryTC,
}
