import { InitialState } from './overview.types'
import { TAccountBalance } from '@/core/types/overview'
import { INCOME_DEFAULT, OVERVIEW_ACCOUNT_SYMBOLS_MAP } from './overview.defaults'
import { OVERVIEW_MAX_SMALL_ASSETS_IN_USDT } from '@/core/constants/overview'

const OVERVIEW_DEFAULT_OVERVIEW = {
  positions: [],
  isDefault: true,
}

const OVERVIEW_DEFAULT_BALANCE: TAccountBalance = {
  accountBalance: '',
  accountBalanceByQuotes: {},
  assets: [],
  symbol: '',
  isDefault: true,
}

const ORDERS_DEFAULT: InitialState['openOrders'][keyof InitialState['openOrders']] = {
  isDefault: true,
  orders: [],
}

const MARKET_DATA_DEFAULT = {}

const initialState: InitialState = {
  positions: {
    USDT_FUTURE: OVERVIEW_DEFAULT_OVERVIEW,
    COIN_FUTURE: OVERVIEW_DEFAULT_OVERVIEW,
    SPOT: OVERVIEW_DEFAULT_OVERVIEW,
    SWAP: OVERVIEW_DEFAULT_OVERVIEW,
    spot: OVERVIEW_DEFAULT_OVERVIEW,
    linear: OVERVIEW_DEFAULT_OVERVIEW,
  },
  positionsErros: [],
  balances: {
    BINANCE_SPOT: {
      ...OVERVIEW_DEFAULT_BALANCE,
      symbol: OVERVIEW_ACCOUNT_SYMBOLS_MAP.BINANCE_SPOT.symbol,
    },
    BINANCE_FUTURE: {
      ...OVERVIEW_DEFAULT_BALANCE,
      symbol: OVERVIEW_ACCOUNT_SYMBOLS_MAP.BINANCE_FUTURE.symbol,
    },
    BINANCE_FUTURE_COIN: {
      ...OVERVIEW_DEFAULT_BALANCE,
      symbol: OVERVIEW_ACCOUNT_SYMBOLS_MAP.BINANCE_FUTURE_COIN.symbol,
    },
    OKX_TRADING: {
      ...OVERVIEW_DEFAULT_BALANCE,
      symbol: OVERVIEW_ACCOUNT_SYMBOLS_MAP.OKX_TRADING.symbol,
    },
    BYBIT_UNIFIED: {
      ...OVERVIEW_DEFAULT_BALANCE,
      symbol: OVERVIEW_ACCOUNT_SYMBOLS_MAP.BYBIT_UNIFIED.symbol,
    },
  },
  totalBalance: '0',
  openOrders: {
    SPOT: ORDERS_DEFAULT,
    USDT_FUTURE: ORDERS_DEFAULT,
    COIN_FUTURE: ORDERS_DEFAULT,
    SWAP: ORDERS_DEFAULT,
    spot: ORDERS_DEFAULT,
    linear: ORDERS_DEFAULT,
  },
  errors: [],
  pendingPositionOrders: null,
  // status: 'loading',
  statusBalances: 'loading',
  statusOrders: 'loading',
  statusPositions: 'loading',
  statusSellAsset: 'idle',
  sellAssetErrorCode: undefined,
  instrumentType: undefined, // market to trade
  accountTypeForInstrument: undefined, // user account for current selected market aka `instrumentType`
  accountLowBalanceLimit: OVERVIEW_MAX_SMALL_ASSETS_IN_USDT.toString(),
  accountType: undefined, // user account for account tabs and other account-specific ui
  tabId: 1,
  isAscending: true,
  isHiddenSmallAssets: false,
  sortingField: 'updateTime',
  isLoadingMargin: false,
  isolatedMargin: {
    isMarginSet: false,
    isMarginSuccess: false,
  },
  accountV3: null,
  deletingOrders: {
    SPOT: {},
    USDT_FUTURE: {},
    COIN_FUTURE: {},
    SWAP: {},
    spot: {},
    linear: {},
  },
  deletingPositions: {
    SPOT: {},
    USDT_FUTURE: {},
    COIN_FUTURE: {},
    SWAP: {},
    spot: {},
    linear: {},
  },
  allCoins: [],
  exchangeInstruments: [],
  exchangeAccounts: [],
  exchangeType: 'BINANCE_BROKER_FUTURE',
  exchangeInfo: undefined,
  exchangeInfoUsdm: undefined,
  linearInstruments: undefined,
  balanceOutputConfig: {
    showMargin: true,
  },
  incomeStatistics: {
    BINANCE_VIP: {
      BINANCE_FUTURE: INCOME_DEFAULT,
      BINANCE_FUTURE_COIN: INCOME_DEFAULT,
      BINANCE_SPOT: INCOME_DEFAULT,
      BYBIT_UNIFIED: INCOME_DEFAULT,
      OKX_TRADING: INCOME_DEFAULT,
    },
    BINANCE_BROKER_FUTURE: {
      BINANCE_FUTURE: INCOME_DEFAULT,
      BINANCE_FUTURE_COIN: INCOME_DEFAULT,
      BINANCE_SPOT: INCOME_DEFAULT,
      BYBIT_UNIFIED: INCOME_DEFAULT,
      OKX_TRADING: INCOME_DEFAULT,
    },
    BYBIT_BROKER: {
      BINANCE_FUTURE: INCOME_DEFAULT,
      BINANCE_FUTURE_COIN: INCOME_DEFAULT,
      BINANCE_SPOT: INCOME_DEFAULT,
      BYBIT_UNIFIED: INCOME_DEFAULT,
      OKX_TRADING: INCOME_DEFAULT,
    },
    OKX_BROKER: {
      BINANCE_FUTURE: INCOME_DEFAULT,
      BINANCE_FUTURE_COIN: INCOME_DEFAULT,
      BINANCE_SPOT: INCOME_DEFAULT,
      BYBIT_UNIFIED: INCOME_DEFAULT,
      OKX_TRADING: INCOME_DEFAULT,
    },
    OKX_AFFILIATE: {
      BINANCE_FUTURE: INCOME_DEFAULT,
      BINANCE_FUTURE_COIN: INCOME_DEFAULT,
      BINANCE_SPOT: INCOME_DEFAULT,
      BYBIT_UNIFIED: INCOME_DEFAULT,
      OKX_TRADING: INCOME_DEFAULT,
    },
  },
  marketData: {
    SPOT: MARKET_DATA_DEFAULT,
    USDT_FUTURE: MARKET_DATA_DEFAULT,
    COIN_FUTURE: MARKET_DATA_DEFAULT,
    SWAP: MARKET_DATA_DEFAULT,
    spot: MARKET_DATA_DEFAULT,
    linear: MARKET_DATA_DEFAULT,
  },
  byBitInstruments: undefined,
  okxInstruments: undefined,
  walletBalanceBybit: null,
}

export default initialState
