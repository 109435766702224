import { FC } from 'react'
import { t } from '@lingui/macro'
import { Button } from '@/ui/kit'
import { ReferralCode } from '@/ui/molecules/ReferralCode'
import { ReferralCodeAddForm } from '@/ui/molecules/ReferralCodeAddForm'
import { useAppSelector, useToggle } from '@/utils'
import s from './style.module.scss'
import EmptyList from '@/ui/molecules/EmptyList'

const ReferralCodes: FC = () => {
  const { overview } = useAppSelector(state => state.referrals)
  const { customCodes } = overview

  const [isOpen, toggle] = useToggle(false)

  return (
    <div className={s.root}>
      <div className={s.headingWrap}>
        <div className={s.heading}>{t({ id: 'referralPage.yourCodes', message: 'Your referral codes' })}</div>
        <Button.AccentDark label={t({ id: 'referralPage.addNewCodeBtn', message: 'Add new code' })} onClick={toggle} />
      </div>
      <div className={s.codes}>
        {customCodes.map(code => (
          <ReferralCode key={code.code} code={code} />
        ))}

        {!customCodes.length && (
          <EmptyList label={t({ id: 'referralPage.noCodes', message: 'Your custom codes will be displayed here' })} />
        )}
      </div>

      <ReferralCodeAddForm isOpen={isOpen} onClose={toggle} />
    </div>
  )
}

export { ReferralCodes }
