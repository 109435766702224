import { axiosMainService } from '@/services/axios'
import { ApiKeyCreationDTO, ApiKeyDTO, IApiKeyStatisticsDTO } from '../models/ApiKeyDTO'
import { AxiosResponse } from 'axios'
import { TRiskManagerSettings } from '@/redux/team/team.types'
import {
  RiskEventDTO,
  RiskEventById,
  TRiskManagerAvailable,
  TRiskManagerTradingStatus,
  RiskEventByIdDTO,
} from '@/redux/riskManager/riskManager.types'

type WithGoogleCodePayload = {
  googleCode: string
}

type WithIdPayload = {
  id: string
}

export type RemoveApiKeysPayload = WithIdPayload & WithGoogleCodePayload
export type GetApiKeyByIdPayload = WithIdPayload

export type CreateApiKeysPayload = WithGoogleCodePayload & {
  name: string
  spotEnabled: boolean
  marginEnabled: boolean
  futuresEnabled: boolean
  restrictionIps: string[]
}

export type TCreateApiKeyPayloadBinance = WithGoogleCodePayload & {
  name: string
  spotEnabled: boolean
  marginEnabled: boolean
  futuresEnabled: boolean
  restrictionIps: string[]
}

export type TCreateApiKeyPayloadOkx = WithGoogleCodePayload & {
  name: string
  spotEnabled: boolean
  passphrase: string
  restrictionIps: string[]
}

export type TCreateApiKeyPayloadBybit = WithGoogleCodePayload & {
  name: string
  spotEnabled: boolean
  restrictionIps: string[]
}

export interface IRestRequestGeneric<T, S> {
  (payload: T): Promise<S>
}

export type TCreateApiKeyPayloadUnion =
  | TCreateApiKeyPayloadBinance
  | TCreateApiKeyPayloadOkx
  | TCreateApiKeyPayloadBybit

export type UpdateApiKeysPayload = {
  id: string
} & CreateApiKeysPayload

export type TGetRiskManagerDTO = Pick<TRiskManagerSettings, 'botHandle' | 'enabled' | 'permissions'> & {
  drawdownType?: TRiskManagerSettings['drawdownType']
  drawdownValue?: TRiskManagerSettings['drawdownValue']
  userHandle?: TRiskManagerSettings['userHandle']
  type?: TRiskManagerSettings['type']
}

export type TSetRiskManagerDTO = {
  userHandle: TRiskManagerSettings['userHandle']
  drawdownValue: TRiskManagerSettings['drawdownValue']
  drawdownType: TRiskManagerSettings['drawdownType']
  permissions: TRiskManagerSettings['permissions']
  type: TRiskManagerSettings['type']
}
export type TUpdateRiskManagerDTO = {
  userHandle: TRiskManagerSettings['userHandle']
  drawdownValue: TRiskManagerSettings['drawdownValue']
  drawdownType: TRiskManagerSettings['drawdownType']
}
export type TRiskManagerTradingStatusDTO = TRiskManagerTradingStatus
export type TRiskManagerAvailableDTO = TRiskManagerAvailable

export const ApiKeysAPI = {
  async getApiKeys() {
    return await axiosMainService.get<ApiKeyDTO[]>('/binance-broker-subaccount/protected/api/v1/apiKey')
  },
  async getApiKeyById({ id }: GetApiKeyByIdPayload) {
    return await axiosMainService.get<ApiKeyDTO>(`/binance-broker-subaccount/protected/api/v1/apiKey/${id}`)
  },
  async createApiKey<T, S>(payload: T): Promise<AxiosResponse<S>> {
    return await axiosMainService.post<S>('/binance-broker-subaccount/protected/api/v2/apiKey', payload)
  },
  async updateApiKey(payload: UpdateApiKeysPayload) {
    return await axiosMainService.patch<ApiKeyCreationDTO>(
      `/binance-broker-subaccount/protected/api/v2/apiKey/${payload.id}`,
      payload
    )
  },
  async createMasterApiKeys() {
    return await axiosMainService.post('/binance-broker-subaccount/protected/api/v1/apiKey/init')
  },
  async removeApiKey({ id, googleCode }: RemoveApiKeysPayload) {
    return await axiosMainService.delete(`/binance-broker-subaccount/protected/api/v2/apiKey/${id}`, {
      data: {
        googleCode,
      },
    })
  },
  async getApiKeysStatistics() {
    return await axiosMainService.get<IApiKeyStatisticsDTO>(
      '/binance-broker-subaccount/protected/api/v1/apiKey/statistic'
    )
  },
  async getRiskManagerSettings() {
    return await axiosMainService.get<TGetRiskManagerDTO>(
      '/binance-broker-subaccount/protected/api/v1/riskManager/settings'
    )
  },
  async postEnsableRiskManager(payload: TSetRiskManagerDTO) {
    return await axiosMainService.post('/binance-broker-subaccount/protected/api/v1/riskManager/settings', payload)
  },
  async putUpdateRiskManagerSettings(payload: TUpdateRiskManagerDTO) {
    return await axiosMainService.put('/binance-broker-subaccount/protected/api/v1/riskManager/settings', payload)
  },
  async deleteDisableRiskManager() {
    return await axiosMainService.delete('/binance-broker-subaccount/protected/api/v1/riskManager/settings')
  },
  async getRiskManagerTradingStatus() {
    return await axiosMainService.get<TRiskManagerTradingStatus>(
      '/binance-broker-subaccount/protected/api/v1/riskManager/users/trading'
    )
  },
  async getRiskManagerEvents({ page, size }: { page: number; size: number }) {
    return await axiosMainService.get(`/risk-manager/protected/api/v1/risk-events?page=${page}&size=${size}`)
  },
  async getRiskManagerEventById(id: string) {
    return await axiosMainService.get<RiskEventByIdDTO>(`/risk-manager/protected/api/v1/risk-events/${id}`)
  },
  async getRiskManagerAvailabe() {
    return await axiosMainService.get<TRiskManagerTradingStatusDTO>(
      '/binance-broker-subaccount/protected/api/v1/riskManager/users/available'
    )
  },
}
