import {
  BalanceAsset,
  DepositWalletAddressResult,
  GetDepositWalletAddressPayload,
  TransactionAssets,
  TransactionsAPI,
  TransferPayload,
  WithdrawAndDepositAssets,
  WithdrawPayload,
} from '../../backend/api'
import { AddressDTO } from '@/backend/models/AddressDTO'
import { TExchangesAvailable } from '@/core/constants'
import { binanceRestClients } from '@/services/axios/instances/binance_clients'
import { bybitRestService } from '@/services/bybit'
import { AccountBill, okxRestService } from '@/services/okx'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { TUsdmIncomeHistory } from '@tigertrade/binance-ts'
import nameof from 'ts-nameof.macro'
import { ThunkError } from '../../types'
import { ThunkCreator } from '../../utils/lib/ThunkCreator'

export const TransferAssetTC = createAsyncThunk<any, TransferPayload, ThunkError>(
  nameof(TransactionsAPI.transferAsset),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TransactionsAPI.transferAsset, payload }, thunkAPI)
  }
)

export const GetAssetsForTransactionsTC = createAsyncThunk<TransactionAssets, void, ThunkError>(
  nameof(TransactionsAPI.getAssetsForTransactions),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TransactionsAPI.getAssetsForTransactions, payload }, thunkAPI)
  }
)

export const GetDepositWalletAddressTC = createAsyncThunk<
  DepositWalletAddressResult,
  GetDepositWalletAddressPayload,
  ThunkError
>(nameof(TransactionsAPI.getDepositWalletAddress), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: TransactionsAPI.getDepositWalletAddress, payload }, thunkAPI)
})

export const GetAssetsForWithdrawAndDepositTC = createAsyncThunk<WithdrawAndDepositAssets, void, ThunkError>(
  nameof(TransactionsAPI.getAssetsForWithdrawAndDeposit),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TransactionsAPI.getAssetsForWithdrawAndDeposit, payload }, thunkAPI)
  }
)

export const GetCoinsBalanceTC = createAsyncThunk<BalanceAsset[], void, ThunkError>(
  nameof(TransactionsAPI.getCoinsBalance),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TransactionsAPI.getCoinsBalance, payload }, thunkAPI)
  }
)

export const WithdrawAssetTC = createAsyncThunk<any, WithdrawPayload, ThunkError>(
  nameof(TransactionsAPI.withdrawAsset),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TransactionsAPI.withdrawAsset, payload }, thunkAPI)
  }
)

export const GetWithdrawAddressTC = createAsyncThunk<AddressDTO[], void, ThunkError>(
  nameof(TransactionsAPI.getWithdrawAddress),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: TransactionsAPI.getWithdrawAddress, payload }, thunkAPI)
  }
)

export const GetExchangeHistoryTC = createAsyncThunk<
  AccountBill[] | TUsdmIncomeHistory[] | any,
  {
    params: Record<string, string | number>
    exchangeType: TExchangesAvailable
  },
  ThunkError
>(nameof(okxRestService.getAccountBills), async (payload, thunkAPI) => {
  return await ThunkCreator(
    {
      apiMethod: async requestPayload => {
        switch (requestPayload.exchangeType) {
          case 'BINANCE_BROKER_FUTURE':
          case 'BINANCE_VIP':
            return binanceRestClients.usdm?.getIncomeHistory(requestPayload.params)
          case 'OKX_BROKER':
            return okxRestService.getAccountBills(requestPayload.params)
          case 'BYBIT_BROKER':
            return bybitRestService.getHistories(requestPayload.params)
        }
      },
      payload,
    },
    thunkAPI
  )
})
