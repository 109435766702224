import { FC, memo, useCallback, useMemo } from 'react'
import { Checkbox, SkeletonList } from '@/ui/kit'
import { TabBar, TabBarPropsType } from '@/ui/molecules/TabBar'
import AssetsContent from '../AssetsContent'
import { accountNames, useActions, useAppSelector } from '@/utils'
import style from './style.module.scss'
import { overviewActions } from '@/redux/overview/overview.slice'
import { OVERVIEW_ACCOUNTS } from '@/core/constants/overview'
import { TAccountType } from '@/core/types/overview'
import { t } from '@lingui/macro'
import { useOverview } from '@/utils/hooks/useOverview'

const AssetsRightBar: FC = () => {
  const { statusBalances, isHiddenSmallAssets, accountLowBalanceLimit } = useAppSelector(state => state.overview)

  const { accountType, exchangeAccounts } = useAppSelector(state => state.overview)
  const { setAccountType, setIsHiddenSmallAssets } = useActions(overviewActions)

  const {
    balanceByAccount: { assets },
  } = useOverview()

  const tabsHandler = useCallback((tab: string) => {
    if (OVERVIEW_ACCOUNTS.includes(tab as TAccountType)) setAccountType(tab as TAccountType)
  }, [])

  function toggleHideAssets() {
    setIsHiddenSmallAssets(!isHiddenSmallAssets)
  }

  const tabs = useMemo<TabBarPropsType['tabs']>(
    () =>
      exchangeAccounts.map(account => {
        return {
          id: account,
          text: accountNames[account].name,
        }
      }),
    [exchangeAccounts]
  )

  return (
    <div className={style.rightBar}>
      <div className={style.wrapper}>
        <TabBar className={style.tab} tabs={tabs} setActiveTab={tabsHandler} activeTab={accountType} />
        <div className={style.hideAssets}>
          <Checkbox
            checked={isHiddenSmallAssets}
            className={style.checkbox}
            onChange={toggleHideAssets}
            disabled={assets.length < 1}
          >
            {t({
              id: 'assets.showLowBalance.checkbox',
              comment: 'Hide small assets',
            })}
            {` ${accountLowBalanceLimit}`}
          </Checkbox>
        </div>
      </div>
      {statusBalances === 'loading' ? <SkeletonList /> : <AssetsContent />}
    </div>
  )
}

export default memo(AssetsRightBar)
