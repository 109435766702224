import { createAsyncThunk } from '@reduxjs/toolkit'
import nameof from 'ts-nameof.macro'
import { OverviewAPI } from '@/backend/api'
import { ThunkError } from '@/types'
import { ThunkCreator } from '@/utils/lib/ThunkCreator'
import {
  AssetSellPayloadDTO,
  AssetSellResponseDTO,
  TCancelAllOpenOrdersPayload,
  TCancelOpenOrderPayloadUnion,
  TCancelOpenOrderResponse,
  TCloseAllPositionsPayload,
  TCloseAllPositionsResponse,
  TClosePositionPayload,
  TClosePositionResponse,
  TSetPositionMarginPayloadDTO,
  TSetPositionMarginResponseDTO,
} from '@/backend/models/OverviewDTO'
import { ISpotAllCoinsInfo, TFuturesUsdAccountInformationRaw, TUsdmIncomeHistory } from '@tigertrade/binance-ts'
import { InternalApi } from '@/services/rest/tigerGateway'
import { getHistorySinceTrx } from '@/backend/api/overviewAPI'
import { TSellAssetPayloadUnion, TSellAssetResponseUnion } from '@/redux/overview/overview.types'

export const CancelOverviewOpenOrderTC = createAsyncThunk<
  TCancelOpenOrderResponse,
  TCancelOpenOrderPayloadUnion,
  ThunkError
>(nameof(InternalApi.methods.overview.cancelOpenOrder), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: InternalApi.methods.overview.cancelOpenOrder, payload }, thunkAPI)
})

export const CancelAllOverviewOpenOrdersTC = createAsyncThunk<
  TCancelOpenOrderResponse,
  TCancelAllOpenOrdersPayload,
  ThunkError
>(nameof(InternalApi.methods.overview.cancelAllOpenOrders), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: InternalApi.methods.overview.cancelAllOpenOrders, payload }, thunkAPI)
})

export const CloseOverviewPositionTC = createAsyncThunk<TClosePositionResponse, TClosePositionPayload, ThunkError>(
  nameof(InternalApi.methods.overview.closePosition),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: InternalApi.methods.overview.closePosition, payload }, thunkAPI)
  }
)

export const CloseAllOverviewPositionsTC = createAsyncThunk<
  TCloseAllPositionsResponse,
  TCloseAllPositionsPayload,
  ThunkError
>(nameof(InternalApi.methods.overview.closeAllPositions), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: InternalApi.methods.overview.closeAllPositions, payload }, thunkAPI)
})

export const GetAllCointsTC = createAsyncThunk<ISpotAllCoinsInfo[] | undefined, undefined, ThunkError>(
  nameof(OverviewAPI.getAllCoinsInfo),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: OverviewAPI.getAllCoinsInfo, payload }, thunkAPI)
  }
)

export const GetStatisticsSinceMomentTC = createAsyncThunk<
  TUsdmIncomeHistory[],
  Parameters<typeof getHistorySinceTrx>[0],
  ThunkError
>(nameof('GetDailyStatisticsTC'), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: getHistorySinceTrx, payload }, thunkAPI)
})

export const SellAsset = createAsyncThunk<TSellAssetResponseUnion, TSellAssetPayloadUnion, ThunkError>(
  nameof(nameof(InternalApi.methods.overview.sellAsset)),
  async (payload, thunkAPI) => {
    return await ThunkCreator({ apiMethod: InternalApi.methods.overview.sellAsset, payload }, thunkAPI)
  }
)

// export const GetUsdMPositionRiskV3BySymbolTC = createAsyncThunk<any, { symbol: string, payload?: any }, ThunkError
// >('GetUsdMPositionRiskV3BySymbol', async (payload, thunkAPI) => {
//   return await ThunkCreator({
//     apiMethod: OverviewAPI.getUsdMPositionRiskV3BySymbol, payload
//   }, thunkAPI)
// })

export const SetPositionMarginTC = createAsyncThunk<
  TSetPositionMarginResponseDTO,
  TSetPositionMarginPayloadDTO,
  ThunkError
>(nameof(InternalApi.methods.overview.setPositionMargin), async (payload, thunkAPI) => {
  return await ThunkCreator({ apiMethod: InternalApi.methods.overview.setPositionMargin, payload }, thunkAPI)
})

export const GetAccountInformationV3TC = createAsyncThunk<TFuturesUsdAccountInformationRaw, {}, ThunkError>(
  'GetAccountInformationV3TC',
  async (payload, thunkAPI) => {
    return await ThunkCreator(
      {
        apiMethod: OverviewAPI.getAccountInformationV3,
      },
      thunkAPI
    )
  }
)
