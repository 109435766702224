import { Status } from '@/backend/types'
import { FieldErrorType } from '@/types'
import { E_RISK_MANAGER_PERMISSIONS } from '@/redux/team/team.types'
import { TExchangesAvailable } from '@/core/constants'

export type TRiskManagerEventsDTO = {
  events: RiskEventDTO[]
  page: number
  total: number
  size: number
}

export enum E_RISK_MANAGER_DRAWDOWN_TYPE {
  percent = 'percent',
  usdt = 'usdt',
}

export enum E_RISK_MANAGER_TYPE {
  external = 'external',
  internal = 'internal',
}

export type TRiskManagerSettings = {
  enabled: boolean
  drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE
  drawdownValue: number
  permissions: E_RISK_MANAGER_PERMISSIONS[]
  type: E_RISK_MANAGER_TYPE
}

export interface RiskEventDTO {
  eventTime: string
  eventId: string
  reason?: {
    type: string
    sign: 'less' | 'equal' | 'greater'
    drawdownType: E_RISK_MANAGER_DRAWDOWN_TYPE
    drawdownValue: number
  }
  targetBalance: {
    value: number
    symbol: string
  }
  actualBalance: {
    value: number
    symbol: string
  }
}

export type RiskManagerReason = RiskEventDTO['reason'] & {
  drawdownValueFormatted: string
}

export interface RiskEvent extends RiskEventDTO {
  eventTimeFormatted: string
  reason?: RiskManagerReason
  targetBalance: RiskEventDTO['targetBalance'] & {
    formattedValue: string
  }
  actualBalance: RiskEventDTO['actualBalance'] & {
    formattedValue: string
  }
}

export enum E_PositionSide {
  Long = 'long',
  Short = 'short',
}

export interface EventPositionDTO {
  symbol: {
    symbolName: string
    side: E_PositionSide
  }
  entryPrice: number
  volume: {
    baseCurrency: {
      value: number
      symbol: string
    }
    quotationCurrency: {
      value: number
      symbol: string
    }
  }
  price: number
  pnl: {
    value: number
    symbol: string
  }
}

export interface EventPosition extends EventPositionDTO {
  entryPriceFormatted: string
  priceFormatted: string
  volume: EventPositionDTO['volume'] & {
    baseCurrency: EventPositionDTO['volume']['baseCurrency'] & {
      formattedValue: string
    }
    quotationCurrency: EventPositionDTO['volume']['quotationCurrency'] & {
      formattedValue: string
    }
  }
}

export interface RiskEventByIdDTO extends RiskEventDTO {
  positions: EventPositionDTO[]
  exchange: TExchangesAvailable
}

export interface RiskEventById extends RiskEventByIdDTO {
  positions: EventPosition[]
}

export type TRiskManagerAvailable = {
  isRiskManagerAvailable: boolean
  type: E_RISK_MANAGER_TYPE
}

export type TRiskManagerTradingStatus = {
  tradingEnabled: boolean
  until: string
}

export type InitialState = {
  riskManagerSettings: TRiskManagerSettings | undefined
  rmGetRequestStatus: Status
  rmSetRequestStatus: Status
  rmUpdateRequestStatus: Status
  rmDeleteRequestStatus: Status
  tradingStatus: TRiskManagerTradingStatus | undefined
  tradingStatusRequestStatus: Status
  userRequestsListRequestStatus: Status
  errors: FieldErrorType[]
  events: RiskEvent[]
  eventsRequestStatus: Status
  eventById: RiskEventById | null
  eventByIdRequestStatus: Status
  isRmAvailable: boolean
  rmAvailableStatus: Status
  availability: TRiskManagerAvailable | undefined
}
