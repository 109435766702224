import { FC, memo } from 'react'
import { TitleH1 } from '../../atoms/TitleH1'
import AssetsAmountList from '../AssetsAmountList'
import style from './style.module.scss'
import { t } from '@lingui/macro'

const AssetsLeftBar: FC = () => {
  return (
    <div className={style.leftBar}>
      <TitleH1 label={t({ message: 'Your assets', id: 'assts.yourAssetsTitle' })} />
      <AssetsAmountList />
    </div>
  )
}

export default memo(AssetsLeftBar)
