import { FC, useCallback, useEffect, useMemo } from 'react'
import { Button } from '@/ui/kit'
import { useActions, useAppSelector } from '@/utils'
import { Loader } from '@/ui/kit/Loader'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import clsx from 'clsx'

import styles from '../style.module.scss'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { t } from '@lingui/macro'
import { ITeamFormUserSubscriptionProps } from '@/ui/molecules/TeamForm/TeamForm.Types'

export const RiskManagerRiskDisable: FC<ITeamFormUserSubscriptionProps> = props => {
  const { handleBack, handleBackSuccess } = props
  const { rmDeleteRequestStatus } = useAppSelector(state => state.riskManager)
  const { DeleteRiskManagerTC, setDeleteTeamRiskManagerReset } = useActions(riskManagerActions)

  useEffect(() => {
    if (isStatusFinal(rmDeleteRequestStatus)) setDeleteTeamRiskManagerReset()
    if (rmDeleteRequestStatus === 'succeeded') {
      handleBackSuccess && handleBackSuccess()
    }
  }, [handleBackSuccess, rmDeleteRequestStatus, setDeleteTeamRiskManagerReset])

  const onSubmit = useCallback(() => {
    DeleteRiskManagerTC()
  }, [DeleteRiskManagerTC])

  const isLoadingState = useMemo(() => {
    return rmDeleteRequestStatus === 'loading'
  }, [rmDeleteRequestStatus])

  if (isLoadingState) return <Loader />

  return (
    <div className={clsx(styles.root)}>
      <div className={clsx(styles.formItemMedium, styles._withMargin)}>
        <Button.Primary
          label={t({
            id: 'teamRiskDisableForm.submit.apply',
            comment: 'Disable risk-manager',
          })}
          className={clsx(styles.btnWide, styles.redText)}
          onClick={onSubmit}
        />
      </div>
      <div className={styles.formItemMedium}>
        <Button.Primary
          label={t({
            id: 'core.cancel',
            comment: 'Cancel',
          })}
          className={clsx(styles.btnWide, styles.textRed)}
          onClick={handleBack}
        />
      </div>
    </div>
  )
}
