import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { t } from '@lingui/macro'
import { useActions, useAppSelector } from '@/utils'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { E_TEAM_MEMBER_VIEWS } from '@/ui/molecules/TeamWidget/TeamWidget.Types'
import styles from './style.module.scss'
import { InteractiveModal, InteractiveModalParts } from '@/ui/kit'
import { EInteractiveModalVariant } from '@/ui/kit/Modals/InteractiveModal/InteractiveModal'
import { RiskManagerRiskDisable } from './RiskManager.Components/RiskManager.RiskDisable'
import { RiskManagerRiskSettings } from './RiskManager.Components/RiskManager.RiskSettings'
import { RiskManagerRiskSubscription } from './RiskManager.Components/RiskManager.RiskSubscription'
import { RiskManagerError } from './RiskManager.Components/RiskManager.Error'
import { RiskManagerStatus } from './RiskManager.Components/RiskManager.Status'

type RiskManagerType = {
  handleUserInfo?: () => void
}

const RiskManager: FC<RiskManagerType> = props => {
  const { rmSetRequestStatus, rmDeleteRequestStatus, rmUpdateRequestStatus } = useAppSelector(
    state => state.riskManager
  )

  const { GetRiskManagerTC } = useActions(riskManagerActions)
  const [widgetView, setWidgetView] = useState(E_TEAM_MEMBER_VIEWS.NONE)
  const handleActivateCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleActivateSuccess = useCallback(() => {
    GetRiskManagerTC()
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleSettingsClose = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleSettingsCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.RM_DISABLE)
  }, [])

  const handleSettingsSuccess = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleDisableCancel = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  const handleError = useCallback(() => {
    setWidgetView(E_TEAM_MEMBER_VIEWS.ERROR)
  }, [])

  const handleDisableSuccess = useCallback(() => {
    GetRiskManagerTC()
    setWidgetView(E_TEAM_MEMBER_VIEWS.NONE)
  }, [])

  useEffect(() => {
    if (rmSetRequestStatus === 'failed' || rmDeleteRequestStatus === 'failed' || rmUpdateRequestStatus === 'failed') {
      handleError()
    }
  }, [rmSetRequestStatus, rmDeleteRequestStatus, rmUpdateRequestStatus, handleError])

  const modalVariant = useMemo<EInteractiveModalVariant | undefined>(() => {
    return widgetView !== E_TEAM_MEMBER_VIEWS.ERROR && widgetView !== E_TEAM_MEMBER_VIEWS.RM_DISABLE
      ? EInteractiveModalVariant.MEDIUM
      : undefined
  }, [widgetView])

  return (
    <>
      <div className={styles.controls}>
        <RiskManagerStatus setWidgetView={setWidgetView} />
      </div>

      <InteractiveModal isOpen={widgetView !== E_TEAM_MEMBER_VIEWS.NONE} variant={modalVariant}>
        {widgetView === E_TEAM_MEMBER_VIEWS.RM_ACTIVATE && (
          <RiskManagerRiskSubscription
            handleBack={handleActivateCancel}
            handleBackSuccess={handleActivateSuccess}
            handleClose={handleActivateCancel}
            showCloseControl={true}
          />
        )}

        {widgetView === E_TEAM_MEMBER_VIEWS.ERROR && (
          <>
            <InteractiveModalParts.SubHeader text={t({ id: 'riskManager.errorHeading', comment: 'Ошибка' })} />
            <RiskManagerError handleClose={handleSettingsClose} />
          </>
        )}

        {widgetView === E_TEAM_MEMBER_VIEWS.RM_SETTINGS && (
          <RiskManagerRiskSettings
            handleBack={handleSettingsCancel}
            handleBackSuccess={handleSettingsSuccess}
            handleClose={handleSettingsClose}
            showCloseControl={true}
          />
        )}

        {widgetView === E_TEAM_MEMBER_VIEWS.RM_DISABLE && (
          <>
            <InteractiveModalParts.SubHeader
              text={t({
                id: 'teamRiskDisableForm.heading',
                comment: 'Отключить риск-менеджер?',
              })}
            />
            <div className={styles.subtitle}>
              {t({
                id: 'riskManagerDisableForm.description',
                comment: 'Торговля станет доступна через 24 часа.',
              })}
            </div>
            <RiskManagerRiskDisable handleBack={handleDisableCancel} handleBackSuccess={handleDisableSuccess} />
          </>
        )}
      </InteractiveModal>
    </>
  )
}

export { RiskManager }
