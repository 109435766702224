import { Status } from '@/backend/types'

export type TPartnerAchievement = {
  achievementType: EPartnerAchievements
  unlocked: boolean
}

export type TPartnerEntity = {
  partnerKey: EPartners
  awardKey: string
}

export type TPartnerAwardRecord = Record<string, TPartnerEntity>

export type InitialState = {
  partners: TPartnerEntity[]
  partnersByAwardKey: TPartnerAwardRecord
  partner: EPartners | undefined
  hasPartnerAccess: boolean | undefined
  achievements: TPartnerAchievement[]
  awardClaimed: boolean | undefined
  tradingVolume: number | undefined
  achievementsUnlockedCount: number | undefined
  unlockedAll: boolean | undefined
  achievementPending: TPartnerAchievement | undefined
  listStatus: Status
  dataStatus: Status
  claimStatus: Status
}

export enum EPartnerAchievements {
  'kycPassed' = 'kycPassed',
  'depositMade' = 'depositMade',
  'apiKeyCreated' = 'apiKeyCreated',
  'tradeMade' = 'tradeMade',
  'tradingVolume10K' = 'tradingVolume10K',
}

export enum EPartners {
  proscalping = 'proscalping',
  cryptosquad = 'cryptosquad',
}

export type TPartnerEntityDTO = TPartnerEntity[]

export type TPartnerDataDTO = {
  achievements: TPartnerAchievement[]
  awardClaimed: boolean
  tradeVolume: number
}
