import { createSlice } from '@reduxjs/toolkit'
import { transactionsAsyncActions } from './transactions.actions'
import { initialState } from './transactions.state'
import { AssetsForWithdrawAndDeposit } from './transactions.types'
import { WithdrawAndDepositAssets } from '../../backend/api'
import { formatDate } from '../../utils/lib/formatDate'
import { isExchangeBinance, isExchangeBybit, isExchangeOkx } from '@/utils/lib/exchange'
import { handleBinanceExchange, handleBybitExchange, handleOkxExchange } from './transactions.utils'

export const slice = createSlice({
  name: `[Tiger Trade Account Transactions]`,
  initialState,
  reducers: {
    resetWalletAddress(state) {
      state.depositWalletAddress = {
        address: null,
        status: 'idle',
      }
    },
    initTransaction(state, data: { payload: string }) {
      const transactionId = data.payload

      if (transactionId) {
        state.transactionSendResult[transactionId] = { status: 'loading' }
      }
    },
    resetExchangeHistory(state) {
      state.exchangeHistory = initialState.exchangeHistory
    },
    setStartDate(state, { payload }: { payload: string }) {
      state.exchangeHistory.startDate = payload
      state.exchangeHistory.history = []
    },
    setEndDate(state, { payload }: { payload: string }) {
      state.exchangeHistory.endDate = payload
      state.exchangeHistory.history = []
    },
    setStartTime(state, { payload }: { payload: string }) {
      state.exchangeHistory.startTime = payload
      state.exchangeHistory.history = []
    },
    setEndTime(state, { payload }: { payload: string }) {
      state.exchangeHistory.endTime = payload
      state.exchangeHistory.history = []
    },
    setIncomeType(state, { payload }: { payload: string }) {
      state.exchangeHistory.incomeType = payload
      state.exchangeHistory.history = []
    },
    setCoin(state, { payload }: { payload: string }) {
      state.exchangeHistory.coin = payload
      state.exchangeHistory.history = []
    },
  },
  extraReducers: builder => {
    builder
      .addCase(transactionsAsyncActions.GetAssetsForTransactionsTC.pending, (state, action) => {
        state.assetsForTransactions.status = 'loading'
      })
      .addCase(transactionsAsyncActions.GetAssetsForTransactionsTC.fulfilled, (state, action) => {
        state.assetsForTransactions.assets = action.payload
        state.assetsForTransactions.status = 'succeeded'
      })
      .addCase(transactionsAsyncActions.GetDepositWalletAddressTC.fulfilled, (state, { payload }) => {
        if (payload) {
          state.depositWalletAddress.status = 'succeeded'
          state.depositWalletAddress.address = payload
        } else {
          state.depositWalletAddress.status = 'failed'
        }
      })
      .addCase(transactionsAsyncActions.GetDepositWalletAddressTC.pending, state => {
        state.depositWalletAddress.status = 'loading'
      })
      .addCase(transactionsAsyncActions.GetDepositWalletAddressTC.rejected, state => {
        state.depositWalletAddress.status = 'failed'
      })
      .addCase(transactionsAsyncActions.TransferAssetTC.fulfilled, (state, { meta }) => {
        const transactionId = meta.arg.transactionId

        if (transactionId) {
          state.transactionSendResult[transactionId] = {
            status: 'succeeded',
            date: formatDate(new Date()),
          }
        }
      })
      .addCase(transactionsAsyncActions.TransferAssetTC.rejected, (state, { meta }) => {
        const transactionId = meta.arg.transactionId

        if (transactionId) {
          state.transactionSendResult[transactionId] = {
            status: 'failed',
            date: formatDate(new Date()),
          }
        }
      })
      .addCase(transactionsAsyncActions.GetAssetsForWithdrawAndDepositTC.pending, state => {
        state.assetsForWithdrawAndDeposit.assetsStatus = 'loading'
      })
      .addCase(transactionsAsyncActions.GetAssetsForWithdrawAndDepositTC.fulfilled, (state, action) => {
        state.assetsForWithdrawAndDeposit.assets = convertAssetToObject(action.payload)
        state.assetsForWithdrawAndDeposit.assetsStatus = 'succeeded'
      })
      .addCase(transactionsAsyncActions.GetCoinsBalanceTC.pending, state => {
        state.coinBalance.status = 'loading'
      })
      .addCase(transactionsAsyncActions.GetCoinsBalanceTC.rejected, state => {
        state.coinBalance.status = 'failed'
      })
      .addCase(transactionsAsyncActions.GetCoinsBalanceTC.fulfilled, (state, action) => {
        state.coinBalance.balanceAssets = action.payload
        state.coinBalance.status = 'succeeded'
      })
      .addCase(transactionsAsyncActions.WithdrawAssetTC.rejected, (state, { meta }) => {
        state.withdrawAssetResult = {
          status: 'failed',
          date: formatDate(new Date()),
        }
      })
      .addCase(transactionsAsyncActions.GetExchangeHistoryTC.pending, (state, action) => {
        state.exchangeHistory.historyStatus = 'loading'
      })
      .addCase(transactionsAsyncActions.GetExchangeHistoryTC.rejected, (state, action) => {
        state.exchangeHistory.historyStatus = 'failed'
        state.exchangeHistory.history = []
      })
      .addCase(transactionsAsyncActions.GetExchangeHistoryTC.fulfilled, (state, action) => {
        state.exchangeHistory.historyStatus = 'succeeded'
        const exchangeType = action.meta.arg.exchangeType
        if (isExchangeBybit(exchangeType)) {
          handleBybitExchange(state, action)
        } else if (isExchangeOkx(exchangeType)) {
          handleOkxExchange(state, action)
        } else if (isExchangeBinance(exchangeType)) {
          handleBinanceExchange(state, action)
        }
      })
      .addCase(transactionsActions.GetWithdrawAddressTC.fulfilled, (state, action) => {
        state.withdrawAddresses.addresses = action.payload
        state.withdrawAddresses.status = 'succeeded'
      })
      .addCase(transactionsActions.GetWithdrawAddressTC.rejected, (state, action) => {
        state.withdrawAddresses.status = 'failed'
      })
      .addCase(transactionsActions.GetWithdrawAddressTC.pending, (state, action) => {
        state.withdrawAddresses.status = 'loading'
      })
  },
})

export const transactionsActions = {
  ...slice.actions,
  ...transactionsAsyncActions,
}

export const transactionsReducer = slice.reducer

const convertAssetToObject = (assets: WithdrawAndDepositAssets) => {
  const result = assets.reduce<AssetsForWithdrawAndDeposit['assets']>((accumulator, { coin, networks }) => {
    accumulator[coin] = networks

    return accumulator
  }, {})

  return result
}
