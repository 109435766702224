import { InitialState } from './riskManager.types'

export const initialState: InitialState = {
  riskManagerSettings: undefined,
  rmGetRequestStatus: 'idle',
  rmSetRequestStatus: 'idle',
  rmUpdateRequestStatus: 'idle',
  rmDeleteRequestStatus: 'idle',
  tradingStatus: undefined,
  tradingStatusRequestStatus: 'idle',
  userRequestsListRequestStatus: 'idle',
  events: [],
  eventsRequestStatus: 'idle',
  eventById: null,
  eventByIdRequestStatus: 'idle',
  errors: [],
  isRmAvailable: false,
  rmAvailableStatus: 'idle',
  availability: undefined,
}
