import React, { FC, useEffect } from 'react'
import s from '../style.module.scss'
import { Trans } from '@lingui/react'
import { useActions } from '@/utils'
import { riskManagerAsyncActions } from '@/redux/riskManager/riskManager.actions'
import { RiskManager } from '@/ui/molecules/RiskManager'

export const RiskManagerParameters: FC = () => {
  const { GetRiskManagerTC } = useActions(riskManagerAsyncActions)

  // initial data load
  useEffect(() => {
    GetRiskManagerTC()
  }, [GetRiskManagerTC])

  return (
    <div className={s.banner}>
      <div className={s.titleText}>
        <Trans
          id="riskManager.riskManagerParametrs"
          message="Параметры <br/> риск-менеджера"
          components={{
            br: <br />,
          }}
        />
      </div>

      <RiskManager />
    </div>
  )
}
