import { FC, Fragment, useEffect, useState } from 'react'
import s from './style.module.scss'
import { t } from '@lingui/macro'
import { EmptyList } from '@/ui/molecules'
import RiskManagerEvent from '@/ui/organisms/RiskManagerEvent'
import { useActions, useAppSelector } from '@/utils'
import { InfiniteScrollPlate } from '@/ui/atoms/InfiniteScrollPlate'
import { riskManagerActions } from '@/redux/riskManager/riskManager.slice'
import { TitleH2 } from '@/ui/atoms/TitleH2'
import { isStatusFinal } from '@/utils/lib/isStatusFinal'
import { Loader } from '@/ui/kit/Loader'

const DEFAULT_PAGE_SIZE = 20
const INITIAL_PAGE = 0

const RiskManagerRightBar: FC = () => {
  const { events, eventsRequestStatus } = useAppSelector(state => state.riskManager)
  const { GetRiskManagerEventsTC, setEventsReset } = useActions(riskManagerActions)
  const [page, setPage] = useState(INITIAL_PAGE)

  useEffect(() => {
    GetRiskManagerEventsTC({ size: DEFAULT_PAGE_SIZE, page })
  }, [GetRiskManagerEventsTC, page])

  useEffect(() => {
    return () => {
      setEventsReset()
    }
  }, [])

  const nextPage = () => {
    const expectedEventsCount = DEFAULT_PAGE_SIZE * (page + 1)

    if (events.length >= expectedEventsCount) {
      setPage(prev => prev + 1)
    }
  }

  return (
    <div className={s.root}>
      <TitleH2
        label={t({
          id: 'riskManager.eventsTitle',
          message: 'Events',
        })}
        className={s.pageTitle}
      />

      {!!events.length && (
        <Fragment>
          {events.map(event => (
            <RiskManagerEvent key={event.eventTime} event={event} />
          ))}

          <InfiniteScrollPlate scrollCallback={nextPage} />
        </Fragment>
      )}

      {!isStatusFinal(eventsRequestStatus) && <Loader className={s.loader} />}

      {!events.length && isStatusFinal(eventsRequestStatus) && (
        <Fragment>
          <EmptyList
            className={s.empty}
            label={t({ id: 'riskManager.subTitle', comment: 'History of risk manager events will appear here soon' })}
          />
        </Fragment>
      )}
    </div>
  )
}

export { RiskManagerRightBar }
