import { FC } from 'react'
import { Button } from '@/ui/kit'
import clsx from 'clsx'
import styles from '../style.module.scss'
import { t } from '@lingui/macro'
import { SVG } from '@/assets/svg'
import { E_TEAM_MEMBER_VIEWS } from '@/ui/molecules/TeamWidget/TeamWidget.Types'
import { E_RISK_MANAGER_DRAWDOWN_TYPE } from '@/redux/riskManager/riskManager.types'
import { useAppSelector } from '@/utils'

interface RiskManagerStatusProps {
  setWidgetView: (view: E_TEAM_MEMBER_VIEWS) => void
}

export const RiskManagerStatus: FC<RiskManagerStatusProps> = ({ setWidgetView }) => {
  const { riskManagerSettings } = useAppSelector(state => state.riskManager)

  return (
    <>
      <div className={styles.statusWrapper}>
        <div>
          <div className={styles.statusDesc}>{t({ id: 'riskManager.status', comment: 'Статус' })}</div>
          <div className={styles.status}>
            {riskManagerSettings?.enabled ? (
              <>
                {t({ id: 'riskManager.connected', comment: 'Включен' })}
                <SVG.OtherIcons.Shield className={styles.rmIcon} />
              </>
            ) : (
              <>
                {t({ id: 'riskManager.disabled', comment: 'Отключен' })}
                <SVG.OtherIcons.ShieldEmpty className={styles.rmIcon} />
              </>
            )}
          </div>
        </div>
        <div>
          <div className={styles.statusDesc}>{t({ id: 'riskManager.typeStatus', comment: 'Тип просадки' })}</div>
          {riskManagerSettings?.enabled ? (
            <div className={styles.status}>
              {riskManagerSettings?.drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.percent
                ? t({ id: 'riskManager.valuePercent', comment: 'Процентная' })
                : t({ id: 'riskManager.valueFixed', comment: 'Фиксированная' })}
            </div>
          ) : (
            <div className={styles.status}>-</div>
          )}
        </div>
        <div>
          <div className={styles.statusDesc}>{t({ id: 'riskManager.valueStatus', comment: 'Значение просадки' })}</div>
          {riskManagerSettings?.enabled ? (
            <div className={styles.status}>
              {riskManagerSettings.drawdownValue}
              {riskManagerSettings.drawdownType === E_RISK_MANAGER_DRAWDOWN_TYPE.percent ? '%' : ' USDT'}
            </div>
          ) : (
            <div className={styles.status}>-</div>
          )}
        </div>
      </div>

      {riskManagerSettings?.enabled ? (
        <>
          <Button.Primary
            className={styles.button}
            label={t({ id: 'teamWidgetUser.action.rmSettings' })}
            onClick={() => {
              setWidgetView(E_TEAM_MEMBER_VIEWS.RM_SETTINGS)
            }}
          />{' '}
          <Button.Secondary
            className={clsx(styles.button, styles.disableButton)}
            label={t({
              id: 'teamRiskDisableForm.heading',
              comment: 'Отключить риск-менеджер?',
            })}
            onClick={() => {
              setWidgetView(E_TEAM_MEMBER_VIEWS.RM_DISABLE)
            }}
          />
        </>
      ) : (
        <Button.Primary
          className={styles.button}
          label={t({ id: 'teamWidgetUser.action.rmActivate' })}
          onClick={() => {
            setWidgetView(E_TEAM_MEMBER_VIEWS.RM_ACTIVATE)
          }}
        />
      )}
    </>
  )
}
