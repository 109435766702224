import { FC } from 'react'
import s from './style.module.scss'
import { RiskManagerBanner } from '@/ui/organisms/RiskManagerLeftBar/RiskManagerBanner/RiskManagerBanner'
import { RiskManagerParameters } from './RiskManagerParameters/RiskManagerParameters'

const RiskManagerLeftBar: FC = () => {
  return (
    <div className={s.root}>
      <RiskManagerBanner />
      <RiskManagerParameters />
    </div>
  )
}

export { RiskManagerLeftBar }
