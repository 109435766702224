import { calculateLongPercentage, getPercentage, getRatio } from '../../DiaryWeekInfo/components/WeekResult/utils'
import BigNumber from 'bignumber.js'
import {
  DEFAULT_VALUE_PERCENT_SYMBOL,
  DEFAULT_VALUE_WITH_SYMBOL,
  DEFAULT_VALUE_WITHOUT_SYMBOL,
  handleRound,
} from '../../DiaryRightBar/components/WeekCard/WeekCard'

// Function to calculate the sum of net_profit for all weeks in a month
export const calculateMonthlyNetProfit = (netProfit: string) => {
  let totalNetProfit = new BigNumber(0)

  if (netProfit !== null && netProfit !== undefined) {
    totalNetProfit = totalNetProfit.plus(new BigNumber(netProfit))
  }

  if (totalNetProfit.isZero()) {
    return {
      totalNetProfitRounded: DEFAULT_VALUE_WITHOUT_SYMBOL,
      netProfitTotal: totalNetProfit.toFixed(2),
    }
  } else {
    return {
      totalNetProfitRounded: handleRound(totalNetProfit.toNumber()),
      netProfitTotal: totalNetProfit,
    }
  }
}
// Function to calculate the overall long percentage for the month
export const calculateMonthlyLongPercentage = (long_count: number, count: number): string => {
  return calculateLongPercentage(long_count, count)
}

// Function to calculate the overall inverse long percentage for the month
export const calculateMonthlyInversePercentage = (long_count: number, count: number): string => {
  if (long_count === 0 || null === undefined || long_count === undefined || long_count === null) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const longCount = new BigNumber(long_count)
  const countValue = new BigNumber(count)
  const percentage = longCount.dividedBy(countValue).multipliedBy(100)
  const result = new BigNumber(100).minus(percentage)
  const formattedPercentage = result.toFixed(2)
  return formattedPercentage + ' %'
}

// Function to calculate the overall average profit/loss ratio for the month
export const calculateMonthlyProfitLossRatio = (avg_win: string, avg_loss: string): string | number => {
  let totalAvgWin = new BigNumber(0)
  let totalAvgLoss = new BigNumber(0)

  totalAvgWin = totalAvgWin.plus(new BigNumber(avg_win))
  totalAvgLoss = totalAvgLoss.plus(new BigNumber(avg_loss))

  return getRatio(totalAvgWin.toFixed(), totalAvgLoss.toFixed())
}

// Function to calculate the win percentage
const calculateWinPercentage = (totalWin: number): string => {
  if (totalWin === 0 || totalWin === null || totalWin === undefined) {
    return DEFAULT_VALUE_WITH_SYMBOL
  }

  const winTotal = new BigNumber(totalWin)

  if (winTotal.isZero()) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const percentage = winTotal
  const formattedPercentage = percentage.toFixed(2)

  return formattedPercentage + ' $'
}

// Function to calculate the win percentage
const calculateLossPercentage = (totalLoss: number): string => {
  if (totalLoss === 0 || totalLoss === null || totalLoss === undefined) {
    return DEFAULT_VALUE_WITH_SYMBOL
  }

  const lossTotal = new BigNumber(totalLoss)

  if (lossTotal.isZero()) {
    return DEFAULT_VALUE_WITH_SYMBOL
  }

  const percentage = lossTotal
  const formattedPercentage = percentage.toFixed(2)

  return formattedPercentage + ' $'
}

// Function to calculate the overall win percentage for the month
export const calculateMonthlyWinPercentage = (avg_win: string): string => {
  let totalWinSum = new BigNumber(0)

  totalWinSum = totalWinSum.plus(new BigNumber(avg_win))

  return calculateWinPercentage(totalWinSum.toNumber())
}

// Function to calculate the loss per failed trades percentage
export const calculateLossPerFailedTradesPercentage = (
  totalLoss: number,
  totalCount: number,
  winCount: number
): string => {
  if (
    totalLoss === 0 ||
    totalCount === 0 ||
    winCount === 0 ||
    totalLoss === null ||
    totalLoss === undefined ||
    totalCount === null ||
    totalCount === undefined ||
    winCount === null ||
    winCount === undefined
  ) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const totalLossBN = new BigNumber(totalLoss)
  const totalCountBN = new BigNumber(totalCount)
  const winCountBN = new BigNumber(winCount)

  const failedTradesCount = totalCountBN.minus(winCountBN)

  if (failedTradesCount.isZero()) {
    return DEFAULT_VALUE_PERCENT_SYMBOL
  }

  const lossPerFailedTrades = totalLossBN.dividedBy(failedTradesCount).multipliedBy(100)
  const formattedPercentage = lossPerFailedTrades.toFixed(2)

  return formattedPercentage + ' %'
}

// Function to calculate the overall loss per failed trades percentage for the month
export const calculateMonthlyLossPerFailedTradesPercentage = (avg_loss: string): string => {
  let totalLossSum = new BigNumber(0)
  totalLossSum = totalLossSum.plus(new BigNumber(avg_loss))
  return calculateLossPercentage(totalLossSum.toNumber())
}

// Function to calculate the overall percentage for the month
export const calculateMonthlyWinCountPercentage = (win_count: number, count: number): string => {
  let totalWinCountSum = new BigNumber(0)
  let totalCountSum = new BigNumber(0)

  totalWinCountSum = totalWinCountSum.plus(new BigNumber(win_count))
  totalCountSum = totalCountSum.plus(new BigNumber(count))

  return getPercentage(totalWinCountSum.toNumber(), totalCountSum.toNumber())
}

// Function to calculate the total commission for the month
export const calculateMonthlyCommissionSum = (commission: string) => {
  let totalCommissionSum = new BigNumber(0)

  if (commission !== null || commission !== undefined) {
    totalCommissionSum = new BigNumber(commission)
  }
  if (totalCommissionSum.isZero()) {
    return {
      commission: DEFAULT_VALUE_WITHOUT_SYMBOL,
    }
  } else {
    return {
      commission: handleRound(totalCommissionSum.toNumber()),
    }
  }
}

// Function to calculate the total volume for the month
export const calculateMonthlyVolumeSum = (volume: string) => {
  let totalVolumeSum = new BigNumber(0)
  if (volume !== null || volume !== undefined) {
    totalVolumeSum = totalVolumeSum.plus(new BigNumber(volume))
  }

  if (totalVolumeSum.isZero()) {
    return {
      volume: DEFAULT_VALUE_WITHOUT_SYMBOL,
    }
  } else {
    return {
      volume: handleRound(totalVolumeSum.toNumber()),
    }
  }
}

export const getCurrentMonthRange = (): string => {
  const now = new Date()
  const start = new Date(now.getFullYear(), now.getMonth(), 1)
  const end = new Date(now.getFullYear(), now.getMonth() + 1, 0)

  const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const startDate = formatDate(start)
  const endDate = formatDate(end)

  return `${startDate},${endDate}`
}

export const checkStringLength = (input: string): boolean => {
  return input.length > 9
}
